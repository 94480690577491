<template>
  <img v-if="imgSrc" :alt="alt" :src="imgSrc" />
</template>
<script setup>
import clearpathLogo from '@/assets/svg/cxl-clearpath-logo.svg';
import contextLabs from '@/assets/svg/context-labs.svg';
import enLogo from '@/assets/lng/en.png';
import frLogo from '@/assets/lng/fr.png';
import { ref } from 'vue';

const props = defineProps({
  src: {
    type: String,
    default: 'svg/right_tick.svg'
  },
  alt: {
    type: String,
    default: 'alt_'
  }
});
const imgSrc = ref('');

switch (props.src) {
  case 'cxl-clearpath-logo':
    imgSrc.value = clearpathLogo;
    break;
  case 'context-labs':
    imgSrc.value = contextLabs;
    break;
  case 'en':
    imgSrc.value = enLogo;
    break;
  case 'fr':
    imgSrc.value = frLogo;
    break;
  default:
    imgSrc.value = 'svg/right_tick.svg';
    break;
}
</script>
